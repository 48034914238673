import axios from '@axios'

export default {
  namespaced: true,
  state: {
    userSettings: {
      user_deactivation_per_number_of_days: null,
      user_deactivation_minimum_number_of_orders: null,
    },
  },
  getters: {
    userSettings: state => state.userSettings,
  },
  mutations: {
    updateUserSettings(state, payload) {
      state.userSettings = payload
    },
  },
  actions: {
    fetchUserSettings(ctx) {
      const settings = Object.keys(ctx.getters.userSettings)
      return new Promise((resolve, reject) => {
        axios.post('/app-settings/retrieve', { settings })
          .then(response => {
            ctx.commit('updateUserSettings', response.data)
            resolve(response)
          }).catch(error => reject(error))
      })
    },
    saveSettings(ctx, settings) {
      return new Promise((resolve, reject) => {
        axios.post('/app-settings/update', settings)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadSpreadsheet() {
      return new Promise((resolve, reject) => {
        axios.get('/app-settings/builder-config', { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadSpreadsheet(ctx, file) {
      return new Promise((resolve, reject) => {
        const headers = {
          'Content-Type': 'multipart/form-data',
        }
        const formData = new FormData()
        formData.append('spreadsheet', file)

        axios.post('/app-settings/builder-config', formData, headers)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
