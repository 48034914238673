<template>
  <div>
    <b-jumbotron
      bg-variant="primary"
      text-variant="light"
    >
      <template v-slot:header>
        <span class="text-light">
          App Settings
        </span>
      </template>
    </b-jumbotron>

    <b-row align-v="stretch">
      <b-col
        cols="12"
        lg="6"
      >
        <b-card
          no-body
        >
          <div class="mb-0">
            <div class="d-flex justify-content-between container my-1 align-items-center">
              <h4 class="mb-0">
                User Settings
              </h4>
            </div>

            <validation-observer
              #default="{ handleSubmit }"
              ref="userSettingsFormValidator"
            >
              <b-form
                @submit.prevent="handleSubmit(submitUserSettings)"
              >
                <validation-provider
                  #default="validationContext"
                  name="User Deactivation Days"
                  :rules="{ required: true, min_value: 1 }"
                >
                  <b-form-group
                    label="User Deactivation Days"
                    class="container"
                    description="The number of days that a user needs to place X orders before being deactivated"
                  >
                    <b-form-input
                      v-model="userSettings.user_deactivation_per_number_of_days"
                      type="number"
                      number
                      :state="getValidationState(validationContext)"
                    />

                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  name="User Deactivation Minimum Orders"
                  :rules="{ required: true, min_value: 1 }"
                >
                  <b-form-group
                    label="User Deactivation Minimum Orders"
                    class="container"
                    description="The minimum number of orders a customer needs to place before being deactivated"
                  >
                    <b-form-input
                      v-model="userSettings.user_deactivation_minimum_number_of_orders"
                      type="number"
                      number
                      :state="getValidationState(validationContext)"
                    />

                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-form-group
                  class="container"
                >
                  <spinner-button
                    ref="userSettingsSubmitButton"
                    type="submit"
                    variant="primary"
                  >
                    Submit
                  </spinner-button>
                </b-form-group>
              </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-card
          no-body
        >
          <div class="mb-0">
            <div class="d-flex justify-content-between container my-1 align-items-center justify-content-center">
              <h4 class="mb-0">
                Wizard Configuration
              </h4>

              <b-link @click="downloadSpreadsheet">
                Download Starter File
              </b-link>
            </div>

            <validation-observer
              #default="{ handleSubmit }"
              ref="wizardFileFormValidator"
            >
              <b-form
                @submit.prevent="handleSubmit(submitWizardConfig)"
              >
                <validation-provider
                  #default="validationContext"
                  name="Wizard Configuration Spreadsheet"
                  :rules="{ required: true }"
                >
                  <b-form-group
                    label="Upload New Spreadsheet"
                    class="container"
                    description="Be sure to upload the .csv (comma separated values) version of your spreadsheet"
                  >
                    <b-form-file
                      v-model="wizardConfigFile"
                      placeholder="Choose the spreadsheet or drop it here..."
                      drop-placeholder="Drop spreadsheet here..."
                      accept=".csv"
                    />

                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-form-group
                  class="container"
                >
                  <b-alert
                    variant="danger"
                    :show="wizardConfigFileError != null"
                  >
                    <div class="alert-body">
                      <p>{{ wizardConfigFileError }}</p>
                    </div>
                  </b-alert>

                  <spinner-button
                    ref="wizardFileSubmitButton"
                    type="submit"
                    variant="primary"
                  >
                    Submit
                  </spinner-button>
                </b-form-group>
              </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert,
  BRow,
  BCol,
  BCard,
  BJumbotron,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BLink,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  ref,
  onUnmounted,
  computed,
} from '@vue/composition-api'
import store from '@/store'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import { required, minValue } from '@core/utils/validations/validations'
import appSettingsService from '@/services/appSettingsService'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import fileDownload from 'js-file-download'

const APP_SETTINGS_MODULE_KEY = 'app-settings'
const SETTING_TYPE_USERS = 'userSettings'
const SETTING_TYPE_WIZARD_CONFIG = 'wizardConfig'

export default {
  name: 'AppSettings',
  components: {
    BAlert,
    BRow,
    BCol,
    BCard,
    BJumbotron,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormFile,
    BLink,

    SpinnerButton,

    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    // Refs
    const userSettingsFormValidator = ref(null)
    const userSettingsSubmitButton = ref(null)

    const wizardFileFormValidator = ref(null)
    const wizardFileSubmitButton = ref(null)

    // Store
    if (!store.hasModule(APP_SETTINGS_MODULE_KEY)) {
      store.registerModule(APP_SETTINGS_MODULE_KEY, appSettingsService)
    }

    onUnmounted(() => {
      if (store.hasModule(APP_SETTINGS_MODULE_KEY)) {
        store.unregisterModule(APP_SETTINGS_MODULE_KEY)
      }
    })

    const toast = useToast()

    // User Settings
    store.dispatch(`${APP_SETTINGS_MODULE_KEY}/fetchUserSettings`)

    const userSettings = computed({
      get() {
        return store.getters[`${APP_SETTINGS_MODULE_KEY}/userSettings`]
      },
      set(newSettings) {
        store.commit(`${APP_SETTINGS_MODULE_KEY}/updateUserSettings`, newSettings)
      },
    })

    const wizardConfigFile = ref(null)
    const wizardConfigFileError = ref(null)

    // Validation
    const {
      getValidationState,
    } = formValidation()

    const downloadSpreadsheet = () => {
      store.dispatch(`${APP_SETTINGS_MODULE_KEY}/downloadSpreadsheet`)
        .then(response => {
          fileDownload(response.data, 'wizard.csv')
        })
    }

    const submitUserSettings = () => {
      userSettingsSubmitButton.value.start()
      store.dispatch(`${APP_SETTINGS_MODULE_KEY}/saveSettings`, userSettings.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully saved your settings',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong trying to save your settings',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          userSettingsSubmitButton.value.stop()
          userSettingsFormValidator.value.reset()
        })
    }

    const submitWizardConfig = () => {
      store.dispatch(`${APP_SETTINGS_MODULE_KEY}/uploadSpreadsheet`, wizardConfigFile.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully imported wizard configuration!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          wizardConfigFile.value = null
          wizardFileFormValidator.value.reset()
        })
        .catch(error => {
          if (error.response.data.error != null) {
            wizardConfigFileError.value = error.response.data.error
          }
        })
        .finally(() => {
          wizardFileSubmitButton.value.stop()
        })
    }

    return {
      getValidationState,
      downloadSpreadsheet,
      submitUserSettings,
      submitWizardConfig,

      userSettings,
      wizardConfigFile,
      wizardConfigFileError,
      SETTING_TYPE_USERS,
      SETTING_TYPE_WIZARD_CONFIG,

      userSettingsFormValidator,
      userSettingsSubmitButton,

      wizardFileFormValidator,
      wizardFileSubmitButton,
    }
  },
  data: () => ({
    required,
    minValue,
  }),
}
</script>
